.header {
  background-color: orange;
  font-size: 12pt;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  padding-bottom: 3px;
  padding-top: 3px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: left;
  align-content: center;
}

.kiesEenPuzzel {
  align-self: right;
  margin-left: 10px;
}

.big {
  margin-left: 0.3em;
  font-size: larger;
  font-weight: bold;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: orange;
  font-weight: 600;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  top: 100%;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

a {
  color: inherit;
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  .button {
    font-size: 15px;
  }

  .inputList {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .button {
    font-size: 18px;
  }

  .inputList {
    font-size: 19px;
  }
}
