body {
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
}

.focus {
  background-color: orange !important;
}

.foutantw {
  color: red !important;
}

.opmerking {
  text-align: left;
}

/*extra elementen op mobiele devices*/

.extraMobile {
  display: none;
}

.line-break {
  width: 100%;
}

/* Container = de volledige puzzel */
.container {
  display: flex;
  margin-top: 0.5em;
}

/* Clues omschrijvingen + letters ervoor  
.cluesContainer {
} */

.clueMainDiv {
  display: flex;
  align-items: center;
  height: 35px;
}

.clueDivLetter {
  width: 1em;
  margin-right: 10px;
  font-weight: bold;
  font-size: 13px;
}

.clueDivClue {
  font-size: 16px;
  line-height: 0.95; /*interlinie voor clues > 1 lijn */
  text-align: left;
}

/* Canvas raster */

.canvasContainer {
  flex-shrink: 0; /*raster mag niet kleiner worden, clues wel */
}

.mainInputContainer {
  display: flex;
}

.onnodigeInputs {
  float: left;
  line-height: 40px; /* NODIG VOOR VERTICALE POSITIE OPGAVELETTER */
  text-align: right;
  font-size: 15px;
  font-weight: 600;
}

/* Input CSS */
.pixel {
  width: 35px;
  height: 35px;
  float: left;
}

.allInputContainer {
  width: 34px;
  height: 34px;
  position: relative;
  border: 1px solid black;
}

.inputContainer {
  position: inherit;
  text-align: center;
  top: 0.1em;
}

.myInput {
  text-align: center;
  margin-top: 10%;
  width: 100%;
  border: 0;
  font-size: 18px;
  font-weight: 500;
  background-color: transparent;
  text-transform: uppercase;
}

.allInputContainerVert {
  /* FOR VERTICAL WORD*/
  width: 34px;
  height: 34px;
  position: relative;
  background-color: #c7c2c2;
  border: 1px solid black;
}

.inputContainerVert {
  /* FOR VERTICAL WORD */
  position: inherit;
  text-align: center;
  top: 0.15em;
}

.myInputVert {
  text-align: center;
  height: 26.25px;
  width: 26.25px;
  border: 0;
  font-size: 18px;
  font-weight: 500;
  background-color: #c7c2c2;
  text-transform: uppercase;
}

input:focus {
  /* anders komt er een extra border in het geselecteerde vakje */
  outline: none;
}

.divMasker {
  width: 24px;
  position: absolute;
  top: 2.5em;
  left: 2.5em;
  height: 8.75px;
  width: 8.75px;
  font: 9px sans-serif;
  font-weight: 700;
}

.mainInputContainerLetterbalk {
  padding-top: 1em;
  padding-bottom: 0.2em;
  display: flex;
}

.allInputContainerLetterbalk {
  width: 30px;
  height: 35px;
  margin-right: -1px; /* anders dubbele common border */
  border: 1px solid black;
}

.myInputLetterBalk {
  width: 30px;
  height: 35px;
  margin-left: 2px;
  margin-top: -5px;
  text-align: left;
  background-color: transparent;
  color: orangered;
  border: none;
  font-size: 18px;
  font-weight: 550;
  text-transform: uppercase;
}

.divMaskerLetterBalk {
  position: relative;
  bottom: 30%;
  right: 10%;
  text-align: right;
  font: 10px sans-serif;
  font-weight: 700;
}

/* alfabet */
.mainInputContainerAlfabet {
  display: flex;
}

.pixelAlfabet {
  width: 1em;
  font-size: 18px;
  font-weight: 600;
  color: orangered;
  padding-bottom: 0.5em;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: orange;
  font-weight: 600;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  bottom: 100%;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.btnDisabled {
  background-color: rgb(144, 144, 153);
}

/* MEDIAQUERIES */

@media screen and (max-width: 1024px) {
  .clueMainDiv {
    height: 25px;
  }

  .clueDivClue {
    font-size: 95%;
  }

  .mainInputContainer {
    display: flex;
    padding-left: 0.5em;
  }

  .onnodigeInputs {
    line-height: 30px; /* NODIG VOOR VERTICALE POSITIE OPGAVELETTER */
    font-size: 13px;
  }

  .pixel {
    width: 28px;
    height: 25px;
  }

  .allInputContainer {
    width: 27px;
    height: 24px;
  }

  .inputContainer {
    top: 0em;
  }

  .myInput {
    width: 50%;
    height: 15px;
    margin-top: 0%;
    font-size: 15px;
    font-weight: 400;
  }

  .allInputContainerVert {
    width: 27px;
    height: 24px;
  }

  .inputContainerVert {
    top: 0em;
  }

  .myInputVert {
    height: 15px;
    width: 50%;
    margin-top: 0%;
    font-size: 15px;
    font-weight: 400;
  }

  .divMasker {
    top: 60%;
    left: 55%;
    font-weight: 700;
  }

  .allInputContainerLetterbalk {
    width: 28px;
    height: 28px;
  }

  .myInputLetterBalk {
    width: 28px;
    height: 28px;
    margin-top: -5px;
    font-size: 15px;
    color: brown;
    font-weight: 600;
  }

  .divMaskerLetterBalk {
    bottom: 45%;
    right: 5%;
  }

  .button {
    font-size: 16px;
    margin-bottom: 1em;
  }

  .mainInputContainerAlfabet {
    display: flex;
    padding-left: 0.5em;
  }

  .pixelAlfabet {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 15px;
    font-weight: 600;
    color: brown;
  }
}

@media screen and (max-width: 959px) {
  .container {
    flex-direction: column;
    padding-top: 1em;
  }

  .cluesContainer {
    width: 100%;
    order: 2;
    padding-top: 1em;
  }

  .clueMainDiv {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
  }

  .clueDivLetter {
    font-size: 16px;
  }

  .clueDivClue {
    line-height: 110%;
    font-size: 110%;
  }

  .canvasContainer {
    margin-left: -0.8em;
    margin-top: -3%;
  }

  .extraMobile {
    display: block;
    color: rgb(30, 15, 240);
    font-weight: 600;
    top: 0;
    width: 100%;
    padding-bottom: 0.5em;
    z-index: 99;
    background-color: white;
    padding-top: 0.2em;
  }

  .onnodigeInputs {
    line-height: 40px; /* NODIG VOOR VERTICALE POSITIE OPGVELETTER */
    font-size: 14px;
  }

  .pixel {
    width: 33px;
    height: 31px;
  }

  .allInputContainer {
    width: 32px;
    height: 30px;
  }

  .myInput {
    height: 20px;
    font-size: 17px;
    font-weight: 500;
  }

  .allInputContainerVert {
    width: 32px;
    height: 30px;
  }

  .myInputVert {
    height: 20px;
    font-size: 17px;
    font-weight: 500;
    background-color: #c7c2c2;
  }

  .divMasker {
    text-align: right;
    top: 60%;
    left: 60%;
    font: 10px sans-serif;
    font-weight: 700;
  }

  .allInputContainerLetterbalk {
    width: 24px;
  }

  .myInputLetterBalk {
    color: rgb(30, 15, 240);
  }

  .pixelAlfabet {
    font-size: 17px;
    font-weight: 600;
    color: rgb(30, 15, 240);
  }
}

@media screen and (max-width: 600px) {
  .clueDivLetter {
    font-size: 15px;
  }

  .clueDivClue {
    font-size: 100%;
  }

  .extraMobile {
    color: rgb(5, 119, 39);
    margin-left: 1em;
    text-align: left;
  }

  .brederCanvas {
    transform-origin: left top;
    transform: scale(1.3, 1);
  }

  .breedsteCanvas {
    transform-origin: left top;
    transform: scale(1.5, 1);
  }

  .onnodigeInputs {
    font-size: 0.75em;
  }

  .pixel {
    width: 17px;
    height: 32px;
  }

  .allInputContainer {
    width: 16px;
    height: 31px;
  }

  .myInput {
    width: 13px;
    height: 15px;
    margin-left: 0;
    margin-right: 0;
    border: 0;
    padding-left: 0%;
    padding-right: 0%;
    font-size: 15px;
  }

  .allInputContainerVert {
    width: 16px;
    height: 31px;
  }

  .myInputVert {
    width: 13px;
    height: 15px;
    margin-left: 0;
    margin-right: 0;
    border: 0;
    padding-left: 0%;
    padding-right: 0%;
    font-size: 15px;
  }

  .divMasker {
    text-align: center;
    left: 15%;
    font: 9px sans-serif;
  }

  .mainInputContainerLetterbalk {
    padding-left: 0.1em;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
  }

  .pixelLetterBalk {
    padding-bottom: 0.2em;
    width: 20px;
    height: 32px;
  }

  .allInputContainerLetterbalk {
    width: 19px;
    height: 31px;
  }

  .myInputLetterBalk {
    text-align: center;
    width: 19px;
    height: 15px;
    margin-left: 0;
    margin-right: 0;
    border: 0;
    padding-left: 0%;
    padding-right: 0%;
    font-size: 15px;
    color: rgb(5, 119, 39);
  }

  .divMaskerLetterBalk {
    bottom: 10%;
    text-align: center;
    font: 10px sans-serif;
  }

  .pixelAlfabet {
    padding-bottom: 1em;
    font-size: 14.5px;
    font-weight: 600;
    width: 0.85em;
    color: rgb(5, 119, 39);
  }

  .button {
    font-size: 16px;
    margin-bottom: 1em;
  }
}
